import { Environment } from './environment.interface';
import { version } from './version';

export const environment: Environment = {
	version: version,
	apiBaseUrl: 'https://vaarwel-api.newstory.dev/uat2/',
	source: 'vve',
	// gtmId: undefined,
	apm: {
		url: 'https://apm.newstory.cloud',
		serviceName: 'questionnaire',
		environment: 'UAT2',
		origins: [
			'https://uat2-vaarwel-questionnaire.newstory.dev',
			'https://vaarwel-api.newstory.dev',
		],
		labels: {
			group: 'vaarwel',
		},
	},
};
